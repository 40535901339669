import { Breadcrumb, withTrace } from '@local/web-design-system';
import { Grid } from '@mui/material';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from 'src/styles/utils';
import { GenericWithProps } from 'src/utilities/types';

import { styles } from './ContentArea.styles';
import { BaseContentAreaProps, ContentAreaProps } from './ContentArea.types';
import { ContentAreaBody } from './ContentAreaBody';
import FooterRow from './ContentAreaFooter/ContentAreaFooter';
import { TitleRow } from './TitleRow';

export const ContentAreaBase = (props: ContentAreaProps) => {
    const {
        content,
        classes,
        applyTrace,
        breadcrumb = [],
        disableContentPadding = false,
        disableHeaderPadding = false,
        withPaper = true,
        noBackground = false,
        showBreadCrumbArea = true,
        showTitleArea = true,
    } = props;

    const noBreadcrumb = breadcrumb.length === 0;

    const contentClass = classnames(classes.content, {
        [classes.contentPadding]: !disableContentPadding,
        [classes.backgroundColor]: noBackground,
    });

    const headerClass = classnames({
        [classes.headerPadding]: !disableHeaderPadding,
        [classes.headerSpacing]: showTitleArea,
    });

    return (
        <Grid container direction="column" className={classes.root} {...applyTrace()}>
            <Grid
                item
                container
                direction="column"
                className={headerClass}
                wrap="nowrap"
                {...applyTrace('header')}
            >
                {showBreadCrumbArea &&
                    (noBreadcrumb ? (
                        <Grid
                            item
                            xs={12}
                            className={classes.emptyBreadcrumbRow}
                            automation-id="content-area-empty-breadcrumb"
                        />
                    ) : (
                        <Grid item xs={12} automation-id="content-area-bread-crumb">
                            <Breadcrumb
                                segments={breadcrumb}
                                applyTrace={applyTrace}
                                classes={{
                                    root: classes.breadCrumbRoot,
                                    segment: classes.breadcrumbSegment,
                                    breadcrumbSegment: classes.breadcrumbSegment,
                                }}
                            />
                        </Grid>
                    ))}
                {showTitleArea ? (
                    <TitleRow
                        {...props}
                        withBorder={props.withTitleBorder}
                        automation-id="content-area-title-row"
                    />
                ) : (
                    <Grid
                        item
                        xs={12}
                        className={classes.emptyTitleRow}
                        automation-id="content-area-empty-title"
                    />
                )}
            </Grid>
            <Grid
                item
                xs={12}
                container
                className={classes.contentRow}
                {...applyTrace('content')}
                automation-id="content-area-body"
            >
                <ContentAreaBody
                    content={content}
                    noBackground={noBackground}
                    withPaper={withPaper}
                    className={contentClass}
                />
            </Grid>
            <Grid
                item
                container
                direction="column"
                className={classes.footerRow}
                wrap="nowrap"
                {...applyTrace('footer')}
                automation-id="content-area-footer-row"
            >
                <FooterRow />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = () => ({});

const withProps: GenericWithProps<BaseContentAreaProps> = compose(
    connect(mapStateToProps),
    withTrace('content-area'),
    withStyles(styles as any),
);

export const ContentArea = withProps(ContentAreaBase as any);
